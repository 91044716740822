import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Nodemailer from "../components/NodeMailer/index"

const Collaborate = () => (
  <Layout>
    <SEO title="Collaborate" />
    <Link to="/">Home</Link>
    <br></br>
    <br></br>
    <br></br>

    <h3>Contact email: hannahmurphyk@gmail.com</h3>

    <br></br>
    <br></br>

    <p>Contact form via NodeMailer coming soon, pending CSS styling</p>

    <Nodemailer />
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
  </Layout>
)
// }
export default Collaborate
